

.nav-links-list {
    list-style: none;
    display: flex;
    gap: 2.5rem;
    text-align: center;
    justify-content: center;
    font-size: 1.5rem;
    padding-top: 0.7rem;



    .nav-links-list-item {
        position: relative;

        a {
            text-decoration: none;
            color: white;
        }

        a:hover {
            color: var(--yellow);
            transition: 0.4s;
        }

        a::after {
            content: "";
            position: absolute;
            background-color: var(--yellow);
            height: 1.5px;
            width: 0;
            left: 0;
            bottom: -4px;
            transition: all 0.5s ease;
        }

        a:hover::after {
            width: 100%;
        }

    }
    

    @media only screen and (max-width: 1500px) {
        font-size: 1.25rem;
    }
    @media only screen and (max-width: 930px) {
        display: none;
    }
    
}

.hamburger-container {
    display: none;
    align-items: center;
    justify-content: center;


  

    .hamburger {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        position: relative;
        text-align: center;
        
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid var(--yellow);
        margin-top: 0.5rem;
        background-color: transparent;

        svg { // hamburger icon
            width: 70%;
            height: 70%;
            color: var(--yellow);
        }

        div { // mobile menu
            position: fixed;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 5;
            padding: 1rem;

            width: 35%;
            height: 100vh;

            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;

            background-color: var(--ochre);
            box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

            svg { // close icon
                width: 35px;
                height: 35px;
                color: var(--primary-dark);
                margin: 0.5rem 1rem;
            }

            ul {
                margin: 0;
                padding: 0;
                height: 100%;
                width: 100%;
                list-style: none;

                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;

                li {
                    margin: 1rem;

                    a {
                        // text-transform: uppercase;
                        color: var(--yellow);
                        font-weight: 800;
                        text-decoration: none;
                        font-size: 1.2rem;
                        font-weight: 500;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                        color: var(--primary-deep);
                        }

                        @media screen and (max-width: 800px) {
                            font-size: 1.5rem;
                        }
                        @media screen and (max-width: 780px) {
                            font-size: 1.35rem;
                        }
                        @media screen and (max-width: 430px) {
                            font-size: 1.5rem;
                        }
                        @media screen and (max-width: 380px) {
                            font-size: 1.6rem;
                        }
                        @media screen and (max-width: 300px) {
                            font-size: 2.3rem;
                        }

                     
                    }
                }
            }


            @media screen and (max-width: 800px) {
                width: 60%;
            }
            @media screen and (max-width: 500px) {
                width: 70%;
            }
            @media screen and (max-width: 430px) {
                width: 80%;
            }
            @media screen and (max-width: 380px) {
                width: 100%;
            }
            @media screen and (max-width: 299px) {
                width: 110%;
                li {
                    a {margin-left: 2rem;}}
            }
        }


    }


    @media screen and (max-width: 930px) {
        display: flex;
    }


}

