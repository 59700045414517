.header {
    width: 100%;
    position: fixed;
    top: 0;
    justify-content: center;
    background-color: var(--red);
    color: var(--yellow);
    z-index: 100;
    padding-bottom: 15px;
    padding-top: 15px;    
}

.header-title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    font-size: 2.5rem;
    font-family: var(--font-editorial);

    .span-popcorn, .span-projector { scale: 1.3; }
   
    @media (max-width: 600px) {
        gap: 3rem;
        .span-popcorn, .span-projector  { scale: 1; }
    }

    @media (max-width: 450px) {
        .span-popcorn, .span-projector  { display: none; }
    }
}

.header-title-text {
    font-family: var(--font-editorial);
    font-size: 3rem;

}

.header-title-text:hover, .span-projector, .span-popcorn {
    cursor: pointer;
}




//     .header-title {
//         font-size: 6vw;
//         gap: 1.5em;
//     }
    

// }