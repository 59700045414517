.login-page,
.signup-page,
.forgot-pw-page,
.update-profile-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    position: relative;

    .login-form-container,
    .signup-form-container,
    .forgot-pw-container,
    .update-profile-form-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 1.1rem;
        width: 100%;

        .login-title,
        .signup-title,
        .forgot-pw-title,
        .update-profile-title {
            text-align: center;
            font-size: 3.5rem;
            margin-bottom: 1.5rem;
            font-family: var(--font-editorial);
            font-weight: normal;
        }

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;
            width: 20%;

            h3 {
                padding-bottom: 0.5rem;
            }


            .input-container {
                width: 100%;
               

                input {
                    color: white;
                    font-size: 1rem;
                    background-color: transparent;
                    border: 1px solid rgba(128, 128, 128, 0.9);
                    border-radius: 15px;
                    width: 100%;
                    font-size: 1.1rem;
                    padding: 8px;
                    padding-left: 10px; 
                }

                input:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    color: var(--yellow);
                }
            }

            button {
                padding: 3px 10px;
                background-color: transparent;
                color: var(--yellow);
                border: 1px solid var(--yellow);
                transition: 0.4s ease-in-out;
                font-size: 1.1rem;
                margin-top: 10px;
            }

            button:hover {
                cursor: pointer;
                background-color: var(--red);
            }

            .btn-update-profile, .btn-login {
                margin-top: 1.5rem;
            }




            @media only screen and (max-width: 1500px) {
                width: 28%;
            }

            @media only screen and (max-width: 1150px) {
               width: 50%;
            }
            @media only screen and (max-width: 700px) {
               width: 70%;
            }
            @media only screen and (max-width: 500px) {
               width: 80%;
            }
            @media only screen and (max-width: 450px) {
               width: 90%;
            }
        
        }

        .update-profile-form {

            .input-container {
                input {
                    background-color: rgba(0, 0, 0);
                    border-radius: 20px;

                  
    
                }
            }

          
        
        }




        .signup-redirect,
        .login-redirect,
        .dashboard-redirect {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 2rem;
            gap: 0.75rem;
            font-size: 1.1rem;
            text-align: center;

            a {
                text-decoration: none;
                color: var(--yellow);
                background-color: transparent;
            }

            a:hover {
                text-decoration: underline;
                transition: 0.5s;
            }
        }

        .dashboard-redirect {
            backdrop-filter: blur(5px);
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 20px;
        }

       


        @media only screen and (max-width: 1375px) {
            width: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            backdrop-filter: blur(5px); 
            padding-bottom: 20px;

        }
    }

    @media only screen and (max-width: 800px) {
        padding-top: 5rem;
        
    }
    @media only screen and (max-width: 400px) {
        .signup-redirect {
            max-width: 60%;
        }
        
    }
}

.error-msg {
    background-color: rgba(139, 0, 0, 0.7);
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    color: var(--pink);
    font-weight: 500;
    font-size: 1.25rem;
    font-weight: bold;
}