.add-film-page,
.edit-film-page {
    display: flex;
    align-items: center;
    justify-content: center;

    .new-film-form,
    .edit-film-form  {
        border: 1px solid white;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.9);
        backdrop-filter: blur(5px); 
        color: white;
        width: 65%;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        padding: 3rem;

        .form-title {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 4rem;

            @media only screen and (max-width: 500px) {
                padding-top: 20px;
            }

        }

        .new-film-form-columns,
        .edit-film-form-columns {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            text-align: left;
            gap: 3rem;
            width: 100%;

            .new-film-form-column-left,
            .edit-film-form-column-left  {
                width: 50%;
            }
            .new-film-form-column-right,
            .edit-film-form-column-right {
                width: 50%;
            }

            .new-film-form-column-left,
            .new-film-form-column-right,
            .edit-film-form-column-left,
            .edit-film-form-column-right {
                display: flex;
                flex-direction: column;
                list-style: none;
                gap: 1.75rem;
                width: 100%;
            }

            .new-film-form-column-left li,
            .new-film-form-column-right li,
            .edit-film-form-column-left li,
            .edit-film-form-column-right li {
                
                display: flex;
                flex-direction: column;
         
                width: 100%;

                input, textarea {
                    width: 100%;
                    color: white;
                    font-size: 1rem;
                    background-color: transparent;
                    border: 1px solid white;
                    border-left: none;
                    border-top: none;
                    border-right: none;
                    width: 100%;
                    font-size: 1.2rem;
                    padding: 12px;
                }

                textarea {
                    color: white;
                    border: 1px solid white;

                    border-left: none;
                    border-top: none;
                    border-bottom-right-radius: 10px;
             
                }

                input:focus, textarea:focus, select:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    color: var(--yellow);
                }

                textarea:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    border-left: none;
                    border-top: none;
                    color: var(--yellow);
                }

                select {
                    background-color: transparent;
                    color: white;
                    border-radius: 10px;
                    font-size: 1rem;
                    margin-top: 12px;
                    padding: 3px;

                }

                select:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    
                }
                
                .btns-fields {
                    display: flex;
                    flex-direction: row;
                    gap: 1.2rem;
                    padding-top: 5px;

                    .field-btn {
                        background-color: transparent;
                        color: white;
                        border: none;
                        font-size: 1.75rem;
                        transition: 0.4s ease-in-out;
                      
                    
                    }

                    .field-btn:hover {
                        cursor: pointer;
                        color: var(--yellow);
                    }
                }

                

            }

            @media only screen and (max-width: 750px) {
                flex-direction: column;
            }
           
         
        }
        .btns-add-film {
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding-top: 30px;

            button {

                padding: 3px 10px;
                background-color: transparent;
                color: var(--yellow);
                border: 1px solid var(--yellow);
                transition: 0.4s ease-in-out;
                
            }

            button:hover {
                cursor: pointer;
                background-color: var(--red);
            }

            @media only screen and (max-width: 1500px) {
                width: 30%;
            }
            @media only screen and (max-width: 850px) {
                width: 50%;
            }
            @media only screen and (max-width: 550px) {
                flex-direction: column;
                gap: 1rem;
                width: 90%;
            }
        }

        .btns-edit-film {
            width: 30%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding-top: 30px;


            button {

                padding: 3px 10px;
                background-color: transparent;
                color: var(--yellow);
                border: 1px solid var(--yellow);
                transition: 0.4s ease-in-out;
                
            }

            button:hover {
                cursor: pointer;
                background-color: var(--red);
            }

            @media only screen and (max-width: 1500px) {
                width: 50%;
            }
            @media only screen and (max-width: 850px) {
                width: 80%;
            }
            @media only screen and (max-width: 550px) {
                flex-direction: column;
                gap: 1rem;
                width: 90%;
            }

        }

        @media only screen and (max-width: 1150px) {
            width: 90%;
        }
        @media only screen and (max-width: 500px) {
            padding: 10px;
        }


        


    }

    @media only screen and (max-width: 1050px) {
     
       padding-bottom: 40rem;
    }
    @media only screen and (max-width: 1000px) {
    
       padding-bottom: 0rem;
    }
    @media only screen and (max-width: 800px) {
       padding-top: 4rem;
       margin-bottom: 25rem;
    }

    @media only screen and (max-width: 500px) {
       margin-bottom: 5rem;
    }

    @media only screen and (max-width: 450px) {
        margin-bottom: 10rem;
    }
    @media only screen and (max-width: 400px) {
        margin-bottom: 7rem;
    }
    
}

.add-tv-page,
.edit-tv-page {
    display: flex;
    align-items: center;
    justify-content: center;

    .new-tv-form,
    .edit-tv-form {
        border: 1px solid white;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.9);
        backdrop-filter: blur(5px); 
        color: white;
        width: 65%;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 1.1rem;
        padding: 3rem;

        .form-title {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 4rem;

            @media only screen and (max-width: 500px) {
                padding-top: 20px;
            }
        }

        .new-tv-form-columns,
        .edit-tv-form-columns {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            text-align: left;
            gap: 3rem;
            width: 100%;

            .new-tv-form-column-left,
            .edit-tv-form-column-left {
                width: 50%;
            }

            .new-tv-form-column-right,
            .edit-tv-form-column-right {
                width: 50%;
                
            }

            .new-tv-form-column-left,
            .new-tv-form-column-right,
            .edit-tv-form-column-left,
            .edit-tv-form-column-right {
                display: flex;
                flex-direction: column;
                list-style: none;
                gap: 1.75rem;
                width: 100%;
            }


            .new-tv-form-column-left li,
            .new-tv-form-column-right li,
            .edit-tv-form-column-left li,
            .edit-tv-form-column-right li {
                
                display: flex;
                flex-direction: column;
         
                width: 100%;

                input, textarea {
                    width: 100%;
                    color: white;
                    font-size: 1rem;
                    background-color: transparent;
                    border: 1px solid white;
                    border-left: none;
                    border-top: none;
                    border-right: none;
                    width: 100%;
                    font-size: 1.2rem;
                    padding: 12px;
                }

                textarea {
                    color: white;
                    border: 1px solid white;

                    border-left: none;
                    border-top: none;
                    border-bottom-right-radius: 10px;
             
                }

                input:focus, textarea:focus, select:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    color: var(--yellow);
                }

                textarea:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    border-left: none;
                    border-top: none;
                    color: var(--yellow);
                }

                select {
                    background-color: transparent;
                    color: white;
                    border-radius: 10px;
                    font-size: 1rem;
                    margin-top: 12px;
                    padding: 3px;

                }

                select:focus {
                    outline: none;
                    border: 1px solid var(--yellow);
                    
                }
            }
                
                .btns-fields {
                    display: flex;
                    flex-direction: row;
                    gap: 1.2rem;
                    padding-top: 5px;

                    .field-btn {
                        background-color: transparent;
                        color: white;
                        border: none;
                        font-size: 1.75rem;
                        transition: 0.4s ease-in-out;
                      
                    
                    }

                    .field-btn:hover {
                        cursor: pointer;
                        color: var(--yellow);
                    }
                }
                @media only screen and (max-width: 750px) {
                    flex-direction: column;
                }  
        }

        .btns-add-tv {
            width: 20%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            margin-top: 3rem;
            margin-bottom: 40px;

            button {

                padding: 3px 10px;
                background-color: transparent;
                color: var(--yellow);
                border: 1px solid var(--yellow);
                transition: 0.4s ease-in-out;
                
            }

            button:hover {
                cursor: pointer;
                background-color: var(--red);
            }
            @media only screen and (max-width: 1500px) {
                width:50%;
            }
            @media only screen and (max-width: 550px) {
                flex-direction: column;
                gap: 1rem;
                width: 90%;
            }
        }

        .btns-edit-tv {
            width: 28%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            margin-top: 3rem;
            margin-bottom: 40px;

            button {

                padding: 3px 10px;
                background-color: transparent;
                color: var(--yellow);
                border: 1px solid var(--yellow);
                transition: 0.4s ease-in-out;
                
            }

            button:hover {
                cursor: pointer;
                background-color: var(--red);
            }


            @media only screen and (max-width: 1500px) {
                width: 38%;
            }
            @media only screen and (max-width: 850px) {
                width: 80%;
            }
            @media only screen and (max-width: 550px) {
                flex-direction: column;
                gap: 1rem;
                width: 90%;
            }
        }

        @media only screen and (max-width: 1150px) {
            width: 90%;
        }
        @media only screen and (max-width: 500px) {
            padding: 10px;
        }
    }

    @media only screen and (max-width: 1050px) {
        padding-bottom: 40rem;
    }
    @media only screen and (max-width: 1000px) {
        padding-bottom: 0rem;
    }
    @media only screen and (max-width: 800px) {
        padding-top: 4rem;
        margin-bottom: 25rem;
    }
    @media only screen and (max-width: 500px) {
        margin-bottom: 5rem;
    }
    @media only screen and (max-width: 450px) {
        margin-bottom: 10rem;
    }
    @media only screen and (max-width: 400px) {
        margin-bottom: 7rem;
    }
}