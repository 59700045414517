.dashboard-page {
    .dashboard-title {
        text-align: center;
        font-size: 2rem;
        margin-bottom: .5rem;
        font-family: var(font-editorial);
    }

    .dashboard-container {
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;

        .btns-dashboard {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;
            padding-top: 2rem;

            button {
                padding: 3px 10px;
                background-color: transparent;
                color: var(--yellow);
                border: 1px solid var(--yellow);
                transition: 0.4s ease-in-out;
                font-size: 1.1rem;
            }
    
            button:hover {
                cursor: pointer;
                background-color: var(--red);
            }
    

        }
        
       
    }

    @media only screen and (max-width: 800px) {
        padding-top: 5rem;
        
    }
}