
.search-page {

    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: auto;
    

    .search-form-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
        width: 95%;

        .film-form, .tv-form {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 1rem;
            width: 100%;

            input {
                border-radius: 10px;
                font-size: 1.2rem;
                border: 1px solid white;
                color: var(--yellow);
                width: 45%;
                background-color: transparent;
                padding: 5px;
                padding-left: 10px;

                @media only screen and (max-width: 1650px) {
                    width: 55%;
                }
                @media only screen and (max-width: 1300px) {
                    width: 60%;
                }
                @media only screen and (max-width: 1300px) {
                    width: 55%;
                }
                @media only screen and (max-width: 1100px) {
                    width: 45%;
                }
                @media only screen and (max-width: 1000px) {
                    width: 55%;
                }
                @media only screen and (max-width: 500px) {
                    width: 85%;
                }
            }

            input:focus {
                outline: none;
                border: 1px solid var(--yellow);
            }

            input::placeholder {
                color: antiquewhite;
            }

            button {
                background-color: var(--red);
                color: white;
                padding: 8px;
                border: 1px solid white;
                font-size: 1.1em;
                font-weight: bold;
                width: 11rem;
                transition: 0.4s ease-in-out;

                @media only screen and (max-width: 600px) {
                   font-size: 0.9rem;  
                }
            }

            button:hover {
                cursor: pointer;
                background-color: var(--red);
                color: var(--yellow);
                border: 1px solid var(--yellow);
            }

            @media only screen and (max-width: 1200px) {
                gap: 2rem;
            }
            @media only screen and (max-width: 500px) {
               flex-direction: column;
            } 
        }

        @media only screen and (max-width: 1100px) {
            flex-direction: column;
            gap: 2rem;
        }   
    }



    .search-titles-container {
   
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 90%;
        margin: auto;

        .card-grid-container {
            width: 100%;
            display: flex;

            @media only screen and (max-width: 450px) {
                width: 50%;
            }
            @media only screen and (max-width: 320px) {
                width: 60%;
            }
            @media only screen and (max-width: 300px) {
                width: 70%;
            }
            .card-grid {
                width: 100%;
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between;
                list-style: none;


                .film-card, .tv-card {
           

                    @media only screen and (max-width: 400px) {
                        height: 380px;
                        width: 200px;


                    }
                 
                    

                    .card-poster-img, .poster-not-available {
                
                        @media only screen and (max-width: 400px) {
                            height: 220px;
                        }
                    }
                
                    .poster-not-available {
                
                        @media only screen and (max-width: 400px) {
                            height: 220px;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 400px) {
            width: 100%;
        }
    }
    @media only screen and (max-width: 1050px) {
        margin-bottom: 42rem;
    }
    @media only screen and (max-width: 800px) {
        margin-top: 3rem;
        margin-bottom: 20rem;
    }
    @media only screen and (max-width: 720px) {
        margin-bottom: 30rem;
    }
    @media only screen and (max-width: 700px) {
        margin-bottom: 0rem;
    }
    @media only screen and (max-width: 600px) {
        margin-bottom: 15rem;
    }
    @media only screen and (max-width: 420px) {
        margin-bottom: 12rem;
    }
}