

.film-card, .tv-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 200px;
    min-width: 120px;
    height: 420px;
    padding: 12px;
    margin: 8px auto;
    border-radius: 15px;
    position: relative;

    .card-poster-img, .poster-not-available {
        border-radius: 10px;
        width: 100%;
        height: 250px;

        @media only screen and (max-width: 320px) {
            height: 190px;
        }
    }

    .poster-not-available {
        border-radius: 10px;
        height: 250px;
        width: 100%;

        @media only screen and (max-width: 320px) {
            height: 190px;
        }
    }

    .card-poster-img:hover, .poster-not-available:hover {
        cursor: pointer;
    }

    .card-info {
        max-width: 100%;
        text-align: center;
        font-size: 17px;
        padding: 8px 0;
        font-weight: bold;

        @media only screen and (max-width: 1500px) {
            font-size: 1rem;
        }
        @media only screen and (max-width: 800px) {
            font-size: 0.9rem;
        }
        @media only screen and (max-width: 320px) {
            font-size: 0.8rem;
        }
    }
    
    .card-btns {
        padding: 0 2px;
        padding-bottom: 10px;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
     
    }

    .add-to-watch-btn,
    .remove-from-to-watch-btn,
    .mark-as-watched-btn,
    .unmark-as-watched-btn {
        border-radius: 50%;
        border: none;
        width: 2.3em;
        height: 2.3em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
    .add-to-watch-btn {
        background-color: var(--green);
        border: 2px solid var(--green);

        svg {
            scale: 0.9;
        }
    }
    
    .remove-from-to-watch-btn {
        background-color: var(--pink);
        border: 2px solid var(--pink);

        svg {
            scale: 0.9;
        }
    }
    
    .mark-as-watched-btn {
        background-color: var(--yellow);
        border: 2px solid var(--yellow);

        svg {
            scale: 0.85;
        }
    }
    
    .unmark-as-watched-btn {
        background-color: black;
        border: 2px solid black;

        svg {
            scale: 0.85;
        }
    }
    
    
    .add-to-watch-btn:hover,
    .remove-from-to-watch-btn:hover,
    .mark-as-watched-btn:hover,
    .unmark-as-watched-btn:hover {
        transform: scale(1.14);
        transition: 0.4s;
        cursor: pointer;
    }
    
    @media (max-width: 550px) {
        .card {
            width: 45%;
        }
    }
    
    /* checkboxes */
    .checkbox-container {
       display: flex;
       align-items: center;
       position: absolute;
    
       input[type='checkbox'] {
    
        cursor: pointer;
        opacity: 0;
        width: 1.75em;
        height: 1.75em;
        margin-top: 1px;
        margin-left: 4px;
        transition: 0.5s ease-in-out;
    
        }
    
       label {
        cursor: pointer;     
        position: absolute;
        display: flex;
        transition: 0.5s ease-in-out;
       }
       label::before {
        cursor: pointer;
        content: '';
        width: 1.75em;
        height: 1.75em;
        border-radius: 50%}
        margin-top: 6px;
        margin-left: 3px;    
    }    

    .checkbox-container {
        label::before {
            border: 2px solid rgb(99, 99, 99);
            background-color: rgba(156, 156, 156, 0.95);
        }

        label:hover::before, .input-type[type='checkbox']:hover + label::before {
            border: 2px solid darkred;
            content: '\2714';
            display: flex;
            justify-content: center;
            align-items: center;
            color: darkred;
        }
    }
    
    .checkbox-container input[type='checkbox']:checked + label::before {
        border: 2px solid darkred;
        content: '\2714';
        color: darkred;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(246, 201, 209);
        transition: 0.4s;  
    }
  
    @media only screen and (max-width: 420px) {
        padding: 8px;
    }   
    @media only screen and (max-width: 320px) {
        padding: 8px;
        height: 350px;
    }
}

.film-card {
    background-color: #9a2424;
    @media only screen and (max-width: 420px) {
       margin-right: 5px;
    }
}

.tv-card {
    background-color: var(--ochre);
}