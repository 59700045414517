.contact-icons {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    gap: 5px;
    flex-direction: column;
    position: fixed;
    left: 0;
    bottom: 0;


    @media screen and (max-width: 800px) {
        top: 68%;
    }
    @media screen and (max-width: 720px) {
        top: 60%;
    }
    @media screen and (max-width: 500px) {
        position:relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

      
    .contact-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid var(--gray-a);
      display: flex;
      justify-content: center;
      align-items: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
     

      svg {
        width: 20px;
        height: 20px;
        padding-top: 3px;
        color: var(--gray-a);
        transition: all 0.3s ease-in-out;

      }



      &:hover {
        border: 1px solid var(--gray);
      
        svg { 
          color: var(--gray);
        }

      }

      @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;

        margin: 0.5rem 0;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }

    .contact-icon-mail, .contact-icon-portfolio {
      svg {
          scale: 1.15;
          
      }
    }
    .contact-icon-github, .contact-icon-code {
      svg {
          scale: 1.1;
          
      }
    }
}