.film-details-page, .tv-details-page {

    align-items: center;
    justify-content: center;
    
    @media only screen and (max-width: 800px) {
        margin-top: 5rem;
    }

    .details {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 30px;
        position: relative;
        padding-right: 25px;
    
        .details-poster {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            width: 35%;
    
            .details-poster-img, .poster-not-available {
                border-radius: 15px;
                width: 65%;
                height: auto;
                border: 8px solid var(--red);
            }

            @media only screen and (max-width: 1500px) {
                width: 40%;
                align-items: center;
                justify-content: center;
            }
    
            @media only screen and (max-width: 900px) {
                width: 50%;
            }
            @media only screen and (max-width: 750px) {
                width: 70%;
            }
            @media only screen and (max-width: 600px) {
                width: 95%;
            }
            @media only screen and (max-width: 500px) {
                width: 100%;
            }
        }
    
        .poster-not-available  {
            border: 8px solid #6F5E3C;
            border-radius: 15px;
            width: 65%;
            height: auto;
        }
    
        @media only screen and (max-width: 750px) {
            flex-direction: column;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            display: flex;
            padding-right: 0px;
        }

        .details-text {
            text-align: left;
            font-size: 1.1rem;
            background-color: var(--red);
            border-radius: 10px;
            width: 55%;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 45px;

            .details-text-container {
                display: flex;
                flex-direction: row;
                gap: 5rem;
                margin-left: 5%;

                .details-text-column-right {
                    width: 40%;
                }

                .details-text-column-left {
                    width: 60%;
                }
        
                .details-text-column-right, .details-text-column-left {
                    display: flex;
                    flex-direction: column;
                    gap: 1.2rem;

                    li {
                        display: flex;
                        flex-direction: row;
                        line-height: 150%;
                        width: 100%;

                        .details-title {
                            color: var(--yellow);
                        }

                        .details-ul {
                            .details-li {
                                padding-left: 30px;
                            
                            }
                        }
                    }

                    .synopsis {
                        text-align: justify;
                    }

                    @media only screen and (max-width: 1500px) {
                        gap: 0.7rem;
                    }
                }

                @media only screen and (max-width: 1150px) {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 90 !important;
                    padding: 0;
            
                    .details-text-column-left,  .details-text-column-right  {
                        width: 100%;
                        padding-left: 20px;
                        padding-right: 20px;
                    }

                    .details-text-column-right {
                        margin-top: -5rem;
                    } 
                }
            }

            @media only screen and (max-width: 850px) {
                font-size: 0.9rem;
            }

            @media only screen and (max-width: 1150px) {
                width: 70%;
                padding: 0;
                padding-top: 40px;
                padding-bottom: 40px;
                margin-left: 0;
            }

            @media only screen and (max-width: 500px) {
                width: 90%;
            
            }
        }
        
    }
    
    @media only screen and (max-width: 1050px) {
        padding-bottom: 40rem;
    }
    @media only screen and (max-width: 1000px) {
        padding-bottom: 25rem;
    }
    @media only screen and (max-width: 700px) {
        padding-bottom: 2rem;
    }
    @media only screen and (max-width: 600px) {
        padding-bottom: 15rem;
    }
    @media only screen and (max-width: 550px) {
        padding-bottom: 6rem;
    }
    @media only screen and (max-width: 500px) {
        padding-bottom: 12rem;
    }
    @media only screen and (max-width: 400px) {
        padding-bottom: 8rem;
    }
    @media only screen and (max-width: 370px) {
        padding-bottom: 0;
    }
}

.details-btns {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    padding: 0 2px;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 65%;
    background-color: var(--red);
    border: 8px solid var(--red);
    border-radius: 10px;
    margin-top: 1rem;
        

    @media only screen and (max-width: 1500px) {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }
    @media only screen and (max-width: 350px) {
        flex-direction: column-reverse;
        gap: 15px;
        width: 50%;
    }

    .add-to-watch-btn,
    .remove-from-to-watch-btn,
    .mark-as-watched-btn,
    .unmark-as-watched-btn {
        border-radius: 50% !important;
        border: none;
        width: 2.4rem;
        height: 2.4rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .add-to-watch-btn {
        background-color: var(--green);
        border: 2px solid var(--green);

        svg {
            scale: 0.9;
        }
    }

    .remove-from-to-watch-btn {
        background-color: var(--pink);
        border: 2px solid var(--pink);

        svg {
            scale: 0.9;
        }
    }

    .mark-as-watched-btn {
        background-color: var(--yellow);
        border: 2px solid var(--yellow);

        svg {
            scale: 0.85;
        }
    }

    .unmark-as-watched-btn {
        background-color: black;
        border: 2px solid black;

        svg {
            scale: 0.85;
        }
    }


    .add-to-watch-btn:hover,
    .remove-from-to-watch-btn:hover,
    .mark-as-watched-btn:hover,
    .unmark-as-watched-btn:hover {
        transform: scale(1.14);
        transition: 0.4s;
        cursor: pointer;
    }

}

.details-btns.tv {
    background-color: var(--ochre);
    border: 8px solid var(--ochre);
}

.tv-details-page {
    .details-poster-img,
    .poster-not-available {
        border: 8px solid var(--ochre) !important;
    }
}


.play-trailer-btn, .edit-btn {
    background-color: black;
    color: white;
    padding: 8px;
    font-size: 1.15em;
    font-weight: bold;
    border: 1px solid  black;
}

.play-trailer-btn:hover, .edit-btn:hover {
    cursor: pointer;
    transform: scale(1.1);
    background-color: var(--black);
    border: 1px solid var(--black);
    color: var(--yellow);
    transition: 0.4s;
    cursor: pointer;
}

.close-trailer-btn {
    background-color: black;
    border: 2px solid white;
    color: white;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 1em;
    border-radius: 50%;
    width: 2.3em;
    height: 2.3em;
    text-align: center;
    margin-right: -1vw;

  
}

.close-trailer-btn:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: 0.3s;
} 

.trailer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;

    @media only screen and (max-width: 700px) {
        align-items: center;
        justify-content: center;
        
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(5px); 
    z-index: 100;
}





@media (max-width: 550px) {
    .card {
        width: 45%;
    }
}