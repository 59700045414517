
.App {
  min-height: 100vh;
  padding-top: 175px;
}


@media (max-width: 800px) {
  .App {
    padding-top: 120px;
  }
}

